import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  public set<ReturnType>(key: string, value: ReturnType): void {
    this._set(key, value, localStorage);
  }

  public get<ReturnType>(key: string): ReturnType | undefined {
    return this._get(key, localStorage);
  }

  public remove(key: string): void {
    return this._remove(key, localStorage);
  }

  private _set<ReturnType>(
    key: string,
    value: ReturnType,
    storage: Storage
  ): void {
    storage.setItem(key, JSON.stringify(value));
  }

  private _get<ReturnType>(
    key: string,
    storage: Storage
  ): ReturnType | undefined {
    const value = storage.getItem(key);
    if (!value) {
      return;
    }
    return JSON.parse(value);
  }

  private _remove(key: string, storage: Storage): void {
    storage.removeItem(key);
  }
}
